import { createStore } from 'redux'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  sidebarShow: 'responsive',
  asideShow: false,
  darkMode: false,
  user: {},
}

const changeState = (state = initialState, { type, ...rest }) => {
  // switch (type) {
  //   case 'set':
  //     return {...state, ...rest }
  //   default:
  //     return state
  // }
  switch (type) {
    case "set":
      return { ...state, ...rest };
    case "set_user":
      return { ...state, ...rest };
    case "set_search_invoice":
      return { ...state, ...rest };
    case 'clear_all':
      return initialState;
    default:
      return state;
  }
}

// const store = createStore(changeState)
// export default store
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, changeState);

export default () => {
  let store = createStore(persistedReducer);
  let persistor = persistStore(store);
  return { store, persistor };
};
